let resumeData = {
  "imagebaseurl": "https://rbhatia46.github.io/",
  "name": "Gabriel Pérez",
  "role": "Full Stack Developer",
  "linkedinId": "Your LinkedIn Id",
  "skypeid": "Your skypeid",
  "roleDescription": "I like dabbling in various parts of frontend development and like to learn about new technologies.",
  "socialLinks": [
    {
      "name": "linkedin",
      "url": "https://www.linkedin.com/in/gabriel-pérez-52557534/",
      "className": "fa fa-linkedin"
    },
    {
      "name": "github",
      "url": "https://github.com/gabiperez",
      "className": "fa fa-github"
    },
    {
      "name": "skype",
      "url": "http://twitter.com/gusan_pe",
      "className": "fa fa-twitter"
    }

  ],
  "aboutme": "Im a software developer with Full Stack training and experience working with technologies such as HTML5, CSS3, JavaScript, Express, React, Redux, Postgres, and NodeJs. More than 10 years of leading teams in different disciplines endorse my commitment to team development. I have been a teacher for more than 7 years and a theater director where I manage over 100 people, jobs that help me improve my soft skills day by day. My creativity, expeditism and constant search for challenges are characteristics that stand out to me. I am an AI enthusiast (GPTChat - Stable Diffusion - Midjourney - Dall-E). My English level is B2 and growing. I am constantly training and seeking personal and professional growth",
  "address": "garielperex@gmail.com",
  "website": "garielperex@gmail.com",
  "education": [
    {
      "UniversityName": "Soy Henry",
      "specialization": "Development Full Stack",
      "MonthOfPassing": "March",
      "YearOfPassing": "2023",
    },
    {
      "UniversityName": "Instituto Universitario Patagonico de las Artes",
      "specialization": "Audiovisual Arts Teacher",
      "MonthOfPassing": "June",
      "YearOfPassing": "2015",
    }
  ],
  "work": [
    {
      "CompanyName": "Soy Henry",
      "specialization": "Full Stack Develpment",
      "MonthOfLeaving": "March",
      "YearOfLeaving": "2023",
      "Achievements": "Web creation with Rest APIs/DBs of my authorship. App design that included: Search, filtering, ordering and creation. Technologies used: Javascript - React - NodeJS - Express - SQL - Postgres - Postman"
    },
    {
      "CompanyName": "Ministerio de Educación y Derechos Humanos de Río Negro",
      "specialization": "Reference in Information and Communication Technologies",
      "MonthOfLeaving": "Actually",
      "YearOfLeaving": " ",
      "Achievements": "Responsible for the technological and communicative plant. Development of interdisciplinary projects that link technology and informatics with traditional pedagogical areas of secondary education. Advising teachers and students on information and communication technologies."
    }
  ],
  "skillsDescription": "Your skills here",
  "skills": [
    {
      "skillname": "HTML5"
    },
    {
      "skillname": "CSS"
    },
    {
      "skillname": "Reactjs"
    }
  ],
  "portfolio": [
    {
      "name": "Museo Patagonico de Ciencias Naturales",
      "description": "Web App with user administration with information management about services provided by the museum.",
      "imgurl": "images/portfolio/console.png",


    },
    {
      "name": "PokeApi",
      "description": "web using Rest APIs/DBs of my authorship. App design that included: Searches, filtering, ordering and creation",
      "imgurl": "images/portfolio/judah.jpg"
    },

  ],

}

export default resumeData